.module-tag-icon {
  padding: 0 10px 0 0;
}
.module-tag-icon svg {
  display: flex;
  align-items: center;
  height: 100%;
}
.ant-list-item-meta-title {
  line-height: 1em;
  padding: 0px;
  margin: 0px;
}
