.mainNavMenuItemder {
  display: flex;
}

.mainNavMenuItemder .module-icon {
  padding-right: 4px;
}
.mainNavMenuItemder i {
  padding-right: 4px;
}

.crm-search-option {
  padding: 0px;
}
.crm-search-option .ant-list-item {
  padding: 0px;
}
.crm-search-option .ant-list-item-meta-title {
  margin-bottom: 0px;
}
